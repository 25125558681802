import React, { useEffect, useState } from "react"
import logo from "../../Assets/Images/logo.png"
import graphic from "../../Assets/Images/brainImage.png"
import pdfDownload from "../../Assets/THE_MEMORY_AID_AP.pdf"
import "./Heading.scss"

const Heading = ({ text, subTask }) => {
  // const [squares7and8, setSquares7and8] = useState("")
  // useEffect(() => {
  //   document.body.classList.toggle("HomePage")
  //   document.documentElement.addEventListener("mousemove", followCursor)
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     document.body.classList.toggle("HomePage")
  //     document.documentElement.removeEventListener("mousemove", followCursor)
  //   }
  // }, [])
  // const followCursor = (event) => {
  //   let posX = event.clientX - window.innerWidth / 2
  //   let posY = event.clientY - window.innerWidth / 6
  //   setSquares7and8(
  //     "perspective(200px) rotateY(" +
  //       posX * 0.009 +
  //       "deg) rotateX(" +
  //       posY * -0.009 +
  //       "deg)"
  //   )
  // }
  return (
    <div className="headingLayout">
      <img
        src={logo}
        alt="Logo"
        className="logo"
        // style={{
        //   transform: squares7and8,
        // }}
      />
      <h2 className="heddingInfo">
        {text}
        {subTask && (
          <>
            <br />
            {subTask}
          </>
        )}
      </h2>
      <img src={graphic} alt="Image" className="graphic" />
      <a href={pdfDownload} className="btn btn-primary pdfDownload" download>
        Download PDF
      </a>
    </div>
  )
}

export default Heading
