import React from "react"
import Heading from "../../components/Heading/Heading"

const ContactUs = () => {
  return (
    <div>
      <Heading text={"Memory Aid App"} subTask={"CONTACT US"} />
      <div className="textSection text-center">
        <p>Email: support@memoryaidapp.com</p>
      </div>
    </div>
  )
}

export default ContactUs
