import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Home from "./views/Home/Home"
import Background from "./views/Background/Background"
import MainLayout from "./components/mainLayout/MainLayout"
import OperatingHints from "./views/OperatingHints/OperatingHints"
import TheApp from "./views/TheApp/TheApp"
import Cost from "./views/Cost/Cost"
import DownloadPDF from "./views/DownloadPDF/DownloadPDF"
import ContactUs from "./views/ContactUs/ContactUs"

function App() {
  const routersPaths = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/background",
          element: <Background />,
        },
        {
          path: "/theApp",
          element: <TheApp />,
        },
        {
          path: "/operatingHints",
          element: <OperatingHints />,
        },
        {
          path: "/cost",
          element: <Cost />,
        },
        {
          path: "/pdf",
          element: <DownloadPDF />,
        },
        {
          path: "/contactUs",
          element: <ContactUs />,
        },
      ],
    },
  ])
  return (
    <>
      <RouterProvider
        fallbackElement={
          <>
            <h1>Loading...</h1>
          </>
        }
        router={routersPaths}
      />
    </>
  )
}

export default App
