import React from "react"
import Heading from "../../components/Heading/Heading"

const DownloadPDF = () => {
  return (
    <div>
      <Heading text={"Download PDF"} />
    </div>
  )
}

export default DownloadPDF
