import React from "react"
import Heading from "../../components/Heading/Heading"

const Background = () => {
  return (
    <div>
      <Heading text={"Memory Aid App"} subTask={"BACKGROUND"} />
      <div className="textSection">
        <p>
          The picture above published in the KHN Organization news in July 2017
          is presented as a representation of Cognitive Decline and Dementia. It
          cannot however be said to represent mild cognitive impairment which
          has become widespread in the elderly as life expectancy increases.
          While the picture exhibits that memory is progressively lost in
          cognitive impairment memory detail remains in the brain but cannot be
          retrieved by the neural mechanism. This is evident from the fact that
          having been unable to recall a specific memory at the time it is
          required it will often re-appear spontaneously minutes, hours or even
          days later. Of course, the subsequent reappearance is of no value in
          reducing the frustration or even panic associated with its failure to
          arrive when needed and even when it has subsequently re-appeared it
          can often vanish again at a crucial future moment.
        </p>
        <p>
          How can this be explained? It is a fault in the memory recovery
          mechanism of the brain being absence of some kind of stimulus which
          triggers the release of the required information. If we have lost the
          stimulus which the brain has made to deliver the memory, we must
          create a new stimulus which is associated with the memory. We must
          then store that stimulus and when we look it up it will provide the
          required memory. As we may forget the new stimulus we must store both
          the original memory and the new stimulus in an easily retrievable
          manner. We can do this on a piece of paper but that is not going to
          work very well because we must carry all our stimuli and memories with
          us and look things up, which will take time and generally be an
          impractical method of recovering forgotten memories.
        </p>
        <p>
          Fortunately, we now have electronic devices to store and retrieve
          information and we can carry information around on an iPhone or an
          Android phone. This first Memory Aid app is based on the iPhone.
        </p>
      </div>
    </div>
  )
}

export default Background
