import React, { useEffect, useState } from "react"
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap"
import "./Header.scss"
import { Link, NavLink } from "react-router-dom"
import logo from "../../Assets/Images/logo.png"

const Header = () => {
  const [color, setColor] = useState("headerLayout")
  useEffect(() => {
    window.addEventListener("scroll", changeColor)
    return function cleanup() {
      window.removeEventListener("scroll", changeColor)
    }
  }, [])
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("headerLayout color")
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("headerLayout")
    }
  }
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  const handleLinkClick = () => {
    setIsNavbarOpen(false) // Close the navbar on link click
  }
  return (
    <Navbar
      bg="light"
      data-bs-theme="light"
      fixed="top"
      expand={false}
      expanded={isNavbarOpen}
      className={color}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image src={logo} title="Logo" width={60} />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={handleNavbarToggle}
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-xxl`}
          aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
          placement="end"
          className="navbarOpen">
          <Offcanvas.Header onHide={handleLinkClick} closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
              <Image src={logo} title="Logo" width={60} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 headerNavBar">
              <NavLink exact to="/" onClick={handleLinkClick}>
                Home
              </NavLink>
              <NavLink to="/background" onClick={handleLinkClick}>
                Background
              </NavLink>
              <NavLink to="/theApp" onClick={handleLinkClick}>
                The App
              </NavLink>
              <NavLink to="/operatingHints" onClick={handleLinkClick}>
                Operating hints
              </NavLink>
              <NavLink to="/cost" onClick={handleLinkClick}>
                Cost
              </NavLink>
              <NavLink to="/pdf" onClick={handleLinkClick}>
                Download pdf
              </NavLink>
              <NavLink to="/contactUs" onClick={handleLinkClick}>
                Contact us
              </NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header
