import React from "react"
import Heading from "../../components/Heading/Heading"

const OperatingHints = () => {
  return (
    <div>
      <Heading text={"Memory Aid App"} subTask={"OPERATING HINTS"} />
      <div className="textSection">
        <p>
          <b>1. </b>
          The key loaded into the device should be as brief as possible to avoid
          errors in recognition which can occur when the App misinterprets the
          spoken key. Although the problem can be easily overcome by entering
          the key as text it is worth changing the key to a verbally
          recognizable form by adding or subtracting words and letters. Here are
          a few examples.
          <br />
          <br />
          My key phrase was the name of a friend called Newson. The response was
          the names of all the members of her family. The App always heard it
          wrongly for example as nuisance. I changed it to two short words “New
          Song” and it remembered and reproduced it without difficulty.
        </p>
        <p>
          <b>2. </b>
          You can store a few key passwords under the Key passwords. Just list
          the response as
          <br />
          <br />
          <ul style={{ display: "inline-block", textAlign: "start" }}>
            <li>Bank password</li>
            <li> Credit card No</li>
            <li> Passport No</li>
          </ul>
          <br />
          <br />
          The order on the paper which could be in most cases easy to create the
          stimulus and we have to have a way of storing it. We can do this with
          a pencil and paper indeed we do store things on a piece of paper all
          the time. Say I cannot remember Joe’s phone number. I write “joe’s
          phone 12345 on a piece of paper. When I need his number I just look it
          up on the paper. The problem is I may have 50 phone numbers I would
          like to remember so I will have to put them in alphabetical order on a
          piece of paper and then I will be able to find joe on the paper. If I
          have several acquaintances called joe’s I will have to write down
          their surnames as well. I will be making a telephone directory on
          which I can find any of the numbers I use a lot.
          <br />
          <br />
          Clearly this will not be very effective but we now have in our world I
          phones on which we can store any name and number by speaking it or
          entering it using a keyboard and it’s easy to retrieve. We also have
          something called an app which can be instructed to store and retrieve
          information entered on a keyboard or by the spoken voice. This is how
          it works on the Memory App. I start by entering the key which is a
          word or words I use to lead me to the information I am looking for.
          Say I keep forgetting the name of my friend Joe’s wife. I press the
          key button and say Joe’s wife. The app answers Margret. It can also
          have the name of his three kids just in case I forgot them. Say I have
          trouble remembering the name of a film star Kirk Douglas but I know he
          was in the film the Vikings. I store the key The Vikings and the
          response Kirk Douglas. If I forget who played Evita in the film key
          word is Evita response is Elaine Page Madona as they have both played
          that role. You can enter any key that gives you information and
          receive the information in the response. The app also works in text
          and in speech so I can quickly look up bobs brother and get the answer
          in text or I can get the answer in speech.
          <br />
          <br />
          Now we come to the real bonus. You may have lost your original trigger
          but within 2 or three uses of a particular retrieval you suddenly
          don’t need to look it up any more its back in your memory. You have
          recovered the hidden memory which never left your brain. You have
          reversed the cognitive impairment of this particular memory.
        </p>
      </div>
    </div>
  )
}

export default OperatingHints
