import React from "react"
import Heading from "../../components/Heading/Heading"

const Cost = () => {
  return (
    <div>
      <Heading text={"Memory Aid App"} subTask={"COST"} />
      <div className="textSection">
        <p>
          The Memory Aid App is available from the App Store and is free for the
          first 14 days. After that, it costs £10 for the first year and £5 to
          renew in each subsequent year.
        </p>
      </div>
    </div>
  )
}

export default Cost
