import React from "react"
import Heading from "../../components/Heading/Heading"
import appIcon from "../../Assets/Images/appIcon.jpeg"
import appShot from "../../Assets/Images/appShot.png"
import appShot1 from "../../Assets/Images/appShot2.png"
import appShot2 from "../../Assets/Images/appShot3.png"
import appShot3 from "../../Assets/Images/appShot4.png"

const TheApp = () => {
  return (
    <div>
      <Heading text={"Memory Aid App "} subTask={"THE APP"} />
      <div className="textSection">
        <p>
          The underlying principle is that when we forget something we always
          have some information relating to what we have forgotten which we can
          use as a key to finding the forgotten information. Here is an example.
          I kept forgetting the name of the film star Kirk Douglas, but I always
          remembered he was in the film “The Vikings” In the App I use The
          Vikings as my key, and I attach it to the name Kirk Douglas which is
          the response I am seeking. This is how it works. The App is opened by
          clicking on the icon on my iPhone.
        </p>
        <img
          src={appIcon}
          alt="Icon"
          width={150}
          style={{ margin: "0 auto", marginBottom: "30px", display: "block" }}
        />
        <p>The display on the screen is as shown below.</p>
        <div className="shotImages">
          <img src={appShot} alt="icon" />
          <img src={appShot1} alt="icon" />
        </div>
        <p>
          The screen colour is either green or blue. We start on a green screen
          so if, when you open the App, the screen is in blue retrieve mode
          slide the toggle button between Record mode and Retrieve mode to the
          left into the green record mode. Our key words are “The Vikings” and
          we enter this key by pressing with the forefinger on the green
          microphone image which is under the words “Press and hold mic button.
          The words “say key phrase” will appear above the mic button. You now
          say “The Vikings” and then remove your finger from the mic button. The
          words above the mic button will now read “press and hold mic button”
          again. When you press the button the words above the microphone will
          say “say response phrase” so keeping your finger on the button you say
          “Kirk Douglas” and then release the button.
        </p>
        <p>
          The screen will now show your key and response phrases and ask “do you
          want to save this data. If the key and response phrases are correct
          press “save” on the right at the bottom of the box. If there is an
          error in Key or Response you can tap the screen and correct the phrase
          by deleting and re-entering the correct phrase for key and or response
          using the keyboard at the bottom of the screen. Alternatively, you can
          press cancel and start again entering the key and response. When you
          are happy with the key and response press save and the screen will say
          Memory Aid data saved successfully. Tap OK.
        </p>
        <img
          src={appShot2}
          alt="icon"
          width={200}
          style={{ margin: "0 auto", marginBottom: "30px", display: "block" }}
        />
        <p>
          You can now check it works by sliding the record/retrieve toggle
          button to the blue retrieve mode, pressing the mic button and saying
          the key phrase “The Vikings”. The App will now give the response Kirk
          Douglas.
        </p>
        <p>
          The system works well in speech mode with short and clearly spoken Key
          phrases. If the key phrase wrongly interpreted by the App you can
          correct it as described above but the App will not always recognize a
          corrected key phrase. If this happens it will state on the screen that
          it does not have a response for the key phrase and will show you the
          incorrect key phrase it has recorded. For that reason, simply
          correcting the key phrase on the App in text does not mean it will now
          be recognised when it is entered with your voice.
        </p>
        <img
          src={appShot3}
          alt="icon"
          width={200}
          style={{ margin: "0 auto", marginBottom: "30px", display: "block" }}
        />

        <p>
          To overcome this problem the App works in text as well as speech. All
          the keys and responses are contained in alphabetical order in the app
          in textual and numerical form. To access this information, press the
          small “edit” icon at the top right of the screen
        </p>
        <p>
          The device now shows all recorded key phrases and responses on the
          screen and you can quickly find a key phrase you have entered by
          scrolling down the screen. It is only the Key phrase which has to be
          accurately recorded and recognized from your voice. The response when
          given in speech will usually have enough information to recognize the
          response you are looking for. Remember even when you are working in
          speech the response will be shown in both voice and text on the
          screen.
        </p>
      </div>
    </div>
  )
}

export default TheApp
