import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Header from "../header/Header"
import Footer from "../Footer/Footer"

const MainLayout = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Header />
      <div className="mainLayout">
        <Outlet />
      </div>
      <Footer />
    </>
  )
}
export default MainLayout
