import React from "react"
import Heading from "../../components/Heading/Heading"
import "./home.scss"

const Home = () => {
  return (
    <div className="homePage">
      <Heading text={"Memory Aid App"}  />
      <div className="textSection">
        <h4>A simple iOS App to aid with early memory loss</h4>
        <p>
          The app allows you to record key words or phrases associated with
          names or information that you often forget and then allows a quick
          reminder to be obtained by saying the key phrase to your phone.
        </p>
        <p>Email: support@memoryaidapp.com</p>
      </div>
    </div>
  )
}

export default Home
